@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
nav {
  top: 0;
  left: 0;
  width: 100%;
  border: 0 !important;
  border-radius: 0 !important;
  box-sizing: border-box;
  z-index: 100;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 0 !important;
}
nav.sticky-navbar {
  position: fixed;
}
.navbar-collapse {
  width: 100%;
  background-color: #333333;
}
nav ul li a {
  color: #878787;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.221em;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: #333333 !important;
}

#header {
  position: relative;
  background: url(/static/media/header-bg.ea95ab7c.jpg);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;
}
#headerLeft,
#headerRight,
#headerRightMe,
.particles-header {
  width: 100%;
  height: 100%;
  position: absolute;
}
.particles-header {
  z-index: 1;
}
#headerLeft {
  z-index: 4;
  background-color: transparent;
  top: 25%;
}
#headerRight,
#headerRightMe {
  right: 10%;
  bottom: -10%;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}
#headerRight {
  z-index: 2;
  background-image: url(/static/media/header-right.dc1e676e.png);
}
#headerRightMe {
  z-index: 3 !important;
  background-image: url(/static/media/header-right-me.51ee7bba.png) !important;
}
.btn-toolbar {
  display: inline-flex;
  margin: 0;
}

/* ------------------------ Portrait ------------------------ */

/* ------- Phones ------- */
@media (max-width: 768px) {
  /* ------- Navbar ------- */
  .navbar-header {
    padding: 10px;
    height: 80px;
  }
  .navbar-toggle {
    padding: 5px 40%;
    width: 100%;
    height: 40px;
    float: none;
  }
  .navbar-toggle span {
    width: 100% !important;
    background-color: #878787 !important;
  }
  .navbar-collapse {
    position: absolute;
    z-index: 3;
  }
  .navbar-collapse-normal {
    bottom: 80px;
  }
  .navbar-collapse-sticky {
    top: 80px;
  }
  nav ul li a {
    text-align: center;
  }
  /* ---------------------- */
  /* ------- Services ------- */
  #servicesBox {
    position: relative !important;
    left: 0 !important;
  }
  #skillsBox {
    position: relative !important;
    right: 0 !important;
  }
  /* ---------------------- */
  /* ------- Contact Card Info ------- */
  .info-icon {
    display: block !important;
    margin: 0 auto !important;
  }
  .info {
    text-align: center !important;
  }
  /* ---------------------- */
  /* ------- Footer ------- */
  #wrapper {
    display: block !important;
  }
  #innerWrapper {
    margin: 0 auto !important;
  }
  /* ---------------------- */
}

/* ------- Tablets and Desktop ------- */
@media (min-width: 769px) {
  /* ------- Navbar ------- */
  nav .container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .navbar-collapse {
    padding: 0;
  }
  nav ul {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
    display: flex;
  }
  nav ul li {
    width: 20%;
    list-style: none;
    text-align: center;
    background-color: #333333;
  }

  nav ul li a {
    line-height: 80px !important;
    padding: 0 20px !important;
    box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0.065),
      inset -1px 0 0 0 rgba(255, 255, 255, 0.065);
    -webkit-box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0.065),
      inset -1px 0 0 0 rgba(255, 255, 255, 0.065);
    border-right: 1px solid #171717;
  }
  /* ---------------------- */
}

/* ------- Portrait Phones ------- */
@media (max-width: 768px) and (orientation: portrait) {
  /* ------- Parallax Layer 0 ------- */
  .parallax-layer {
    height: 400px !important;
  }
  .parallax-layer #boldTextWrapper {
    margin-bottom: 15px !important;
  }
  #parallax1Layer1 {
    left: 0 !important;
    top: 5% !important;
    width: 100% !important;
    height: 400px !important;
  }
  #parallax1Img {
    padding: 20px 50px !important;
  }
  /* ---------------------- */
}

/* ------- Phones & Tablets ------- */
@media (max-width: 1024px) and (orientation: portrait) {
  /* ------- Header ------- */
  #headerLeft,
  #headerRight {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    height: 50%;
  }
  #headerLeft {
    padding-top: 5%;
    text-align: center;
    align-items: center;
  }
  #animatedText {
    margin-bottom: 1rem !important;
  }
  #headerRight,
  #headerRightMe {
    background-position: center;
  }
  #headerRightMe {
    height: 50%;
    top: 50%;
    right: 0;
    left: 0;
  }
  /* ---------------------- */
}

/* ------- Giants ------- */
@media (max-width: 1199px) {
  /* ------- About Section ------- */
  #photoFrame {
    height: 50vh !important;
    background-position: bottom center !important;
  }
  #aboutContent h4,
  #aboutContent h3,
  #aboutContent p {
    text-align: center !important;
  }
  #aboutButton {
    margin: 0 auto;
  }
  #contactCard {
    position: relative !important;
    top: 0 !important;
    margin-top: 60px;
  }
  #contactCardContainer {
    padding: 20px 0 !important;
  }
  #contactCardForm {
    padding: 0 !important;
  }
  #contactCardLayout {
    height: auto !important;
  }
  #mapFrame {
    margin-top: 0 !important;
  }
  /* ---------------------- */
}
@media (min-width: 1200px) {
  /* ------- Responsive Row Config ------- */
  .justify-content-between {
    justify-content: space-between !important;
    display: flex;
    flex-wrap: wrap;
  }
  /* ---------------------- */
}

/* ------------------------ Portrait ------------------------ */

/* ------------------------ Landscape ------------------------ */

@media (max-height: 280px) and (orientation: landscape) {
  /* ------- Header ------- */
  #headerLeft {
    top: 0% !important;
  }
  #headerLeft h3 {
    margin: 0 !important;
  }
  /* ---------------------- */
}

@media (max-height: 320px) and (orientation: landscape) {
  /* ------- Services Parallax ------- */
  #parallax1Layer1 {
    top: 5% !important;
  }
  /* ---------------------- */
}

@media (max-height: 375px) and (orientation: landscape) {
  /* ------- Header ------- */
  #headerLeft {
    top: 5% !important;
  }
  /* ---------------------- */
  /* ------- Services Parallax ------- */
  #parallax1Layer1 {
    left: 0 !important;
    width: 100% !important;
    padding: 0 20px !important;
  }
  /* ---------------------- */
}

/* ------------------------ Landscape ------------------------ */

html {
  background-color: #202020;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: calc(8px + (16 - 8) * ((100vw - 320px) / (1600 - 320))) !important;
  background-color: #202020 !important;
}

.parallax-section {
  background-color: #272727;
}

h1,
h2,
h3,
h4,
p {
  font-weight: 500;
}

h1 {
  font-family: 'Montserrat', serif !important;
}

h2,
h3,
p {
  font-family: 'Open Sans', sans-serif !important;
}

h4 {
  font-family: 'Montserrat', serif !important;
}

