#header {
  position: relative;
  background: url('../../../../assets/images/header/header-bg.jpg');
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;
}
#headerLeft,
#headerRight,
#headerRightMe,
.particles-header {
  width: 100%;
  height: 100%;
  position: absolute;
}
.particles-header {
  z-index: 1;
}
#headerLeft {
  z-index: 4;
  background-color: transparent;
  top: 25%;
}
#headerRight,
#headerRightMe {
  right: 10%;
  bottom: -10%;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}
#headerRight {
  z-index: 2;
  background-image: url('../../../../assets/images/header/header-right.png');
}
#headerRightMe {
  z-index: 3 !important;
  background-image: url('../../../../assets/images/header/header-right-me.png') !important;
}
.btn-toolbar {
  display: inline-flex;
  margin: 0;
}
