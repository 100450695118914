@import url('https://fonts.googleapis.com/css?family=Lato');

nav {
  top: 0;
  left: 0;
  width: 100%;
  border: 0 !important;
  border-radius: 0 !important;
  box-sizing: border-box;
  z-index: 100;
  transition: 0.5s;
  margin-bottom: 0 !important;
}
nav.sticky-navbar {
  position: fixed;
}
.navbar-collapse {
  width: 100%;
  background-color: #333333;
}
nav ul li a {
  color: #878787;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.221em;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: #333333 !important;
}
