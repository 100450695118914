@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

html {
  background-color: #202020;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: calc(8px + (16 - 8) * ((100vw - 320px) / (1600 - 320))) !important;
  background-color: #202020 !important;
}

.parallax-section {
  background-color: #272727;
}

h1,
h2,
h3,
h4,
p {
  font-weight: 500;
}

h1 {
  font-family: 'Montserrat', serif !important;
}

h2,
h3,
p {
  font-family: 'Open Sans', sans-serif !important;
}

h4 {
  font-family: 'Montserrat', serif !important;
}
